import { icon } from 'leaflet';
import markerImage from './images/marker.png';

/**
 * Custom marker leaflet element.
 */
export const markerIcon = icon({
  iconUrl: markerImage,
  iconSize: [43, 62],
  iconAnchor: [22, 62],
  popupAnchor: [0, 0],
});
